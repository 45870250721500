<template>
  <BaseFrontdoor keepOut>
    <OutsideContainer>
      <router-view />
    </OutsideContainer>
  </BaseFrontdoor>
</template>

<script>
import OutsideContainer from "@components/Layout/OutsideContainer.vue";

export default {
  name: "RequestAccount",

  components: {
    OutsideContainer,
  },
};
</script>
