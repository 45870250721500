<template>
  <v-container class="mt-10">
    <v-row justify="center" no-gutters>
      <v-col cols="12" md="7" xl="4" align-self="center">
        <img :src="logo" alt="IG BCE BWS" width="200" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="7" xl="4">
        <slot />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import logo from "@/assets/img/igbce-bws-logo-simple-default.svg";

export default {
  name: "OutsideContainer",

  computed: {
    logo() {
      return logo;
    },
  },
};
</script>
